import TopicForm from "./topic-form";
import Description from "../../components/description";

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams, useLocation } from "react-router-dom";

import {
    Fieldset,
    Text,
    PhoneNumber,
    SelectWithState,
    Textarea,
    Checkbox,
    Contact,
    MultipleChoice,
    Password,
    NumberText,
    DateText,
    NumberValueText,
} from "./inputs";
import { FileUpload } from "./file-upload";
import { restGet } from "../../store/api";
import { updateTopicById } from "../../store/actions";
import { ActionPanel } from "../../components/common/action-panel";
import { FormSubmit } from "../../components/todo/forms/form-submit";
import { AutoCompleteComponent } from "../../components/autocomplete";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { classNames } from "../../lib/classnames";

const tabs = [
    { name: "Add/Edit Record", href: "#", current: true },
    { name: "Attachments", href: "#", current: false },
];

const apiPath = "todoEvents";

export function Form({ formFields, pathname, initialValues }) {
    const dispatch = useDispatch();

    const data = useSelector((state) => state.topic.byId);
    const toggle = useSelector((state) => state.topic.toggle);

    const [tab, setTab] = useState(1);
    const [contactList, setContactList] = useState(false);
    const [open, setOpen] = useState(false);
    const [showHiddenField, setShowHiddenField] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOptionType, setSelectedOptionType] = useState({});
    const [nameType, setNameType] = useState([]);
    const [valueMultiCheckBox, setValueMultiCheckBox] = useState({});

    const [openAccordion, setOpenAccordion] = useState("info1");

    const toggleAccordion = (section) => {
        setOpenAccordion((prevState) =>
            prevState === section ? null : section
        );
    };

    const handleSelectChange = (event) => {
        if (!nameType.includes(event.target.name)) {
            setNameType([...nameType, event.target.name]);
        }

        const selectedValue = event.target.value;
        setSelectedOptionType({
            ...selectedOptionType,
            [event.target.name]: selectedValue,
        });
    };

    const [params] = useSearchParams();

    const location = useLocation();
    const route = (url) => {
        if (url.includes("/new")) {
            return url.split("/new")[0];
        } else if (url.includes("/edit")) {
            return url.split("/edit")[0];
        }
    };

    const reqBody = (values) => {
        let body = {};
        formFields.forEach((el) => {
            if (el.type === "text" && !values.hasOwnProperty(el.name)) {
                body[el.name] = " ";
            } else {
                body[el.name] = values[el.name];
            }
        });
        if (body.hasOwnProperty("broker")) {
            body["broker"] = selectedOption;
        }
        return {
            ...body,
            ...valueMultiCheckBox,
            ...Object.keys(selectedOptionType).reduce((result, key) => {
                if (selectedOptionType[key] !== "Other") {
                    result[key] = selectedOptionType[key];
                }
                return result;
            }, {}),
        };
    };

    const successCallback = (data) => {
        if ("type" in data) {
            setSelectedOptionType(data.type);
        }

        if (
            location.pathname === "/everyday/health/bloodType/edit" &&
            "bloodType" in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                bloodType: data["bloodType"],
                receive: data["receive"],
                donate: data["donate"],
            });
        }

        if (
            (location.pathname ===
                "/about-me/my-essentials/citizenships/edit" ||
                location.pathname ===
                    "/about-me/my-essentials/naturalizationCertificates/edit") &&
            "country" in data
        ) {
            setSelectedOptionType(data.country);
        }

        if (
            (location.pathname ===
                "/about-me/my-essentials/maritalStatus/edit" ||
                location.pathname ===
                    "/about-me/my-essentials/employmentStatus/edit") &&
            "status" in data
        ) {
            data.since = data.since.split("T")[0];
            setSelectedOptionType(data.status);
        }

        if (
            location.pathname === "/about-me/my-family/family/edit" &&
            "category" in data
        ) {
            setSelectedOptionType(data.category);
        }

        if (
            location.pathname === "/about-me/my-family/pets/edit" &&
            "typeOfPet" in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                typeOfPet: data["typeOfPet"],
                neuteredOrSpayed: data["neuteredOrSpayed"],
                collarWithIdTags: data["collarWithIdTags"],
            });
        }

        if (
            location.pathname ===
                "/about-me/my-family/genealogyWebsites/edit" &&
            "source" in data
        ) {
            setSelectedOptionType(data.source);
        }

        if (
            (location.pathname ===
                "/about-me/my-family/genealogySoftware/edit" ||
                location.pathname ===
                    "/everyday/digital-world/passwordManagementSoftware/edit") &&
            "software" in data
        ) {
            setSelectedOptionType(data.software);
        }

        if (
            (location.pathname ===
                "/about-me/my-life-experiences/reflectionsOnMyYouth/edit" ||
                location.pathname ===
                    "/about-me/my-life-experiences/reflectionsOnMyAdultLife/edit") &&
            "moments" in data
        ) {
            setSelectedOptionType(data.moments);
        }

        if (
            (location.pathname ===
                "/about-me/my-life-experiences/myFavoritesInMyYouth/edit" ||
                location.pathname ===
                    "/about-me/my-life-experiences/favoritesInMyAdultLife/edit") &&
            "favorite" in data
        ) {
            setSelectedOptionType(data.favorite);
        }

        if (
            location.pathname ===
                "/about-me/my-life-experiences/reflectionsOnMyCareer/edit" &&
            "reflection" in data
        ) {
            setSelectedOptionType(data.reflection);
        }

        if (
            location.pathname === "/everyday/accounts/utilities/edit" &&
            "utilityType" in data
        ) {
            setSelectedOptionType(data.utilityType);
        }

        if (
            location.pathname ===
                "/everyday/business/businessFormationAgreement/edit" &&
            "agreementType" in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                type: data["type"],
                agreementType: data["agreementType"],
            });
        }

        if (
            location.pathname === "/everyday/business/businessAssets/edit" &&
            "asset" in data
        ) {
            setSelectedOptionType(data.asset);
        }

        if (
            location.pathname ===
                "/everyday/business/businessFinancialStatements/edit" &&
            "statementType" in data
        ) {
            setSelectedOptionType(data.statementType);
        }

        if (
            location.pathname ===
                "/everyday/digital-world/internetAccess/edit" &&
            "accessTech" in data
        ) {
            setSelectedOptionType(data.accessTech);
        }

        if (
            location.pathname ===
                "/everyday/digital-world/backUpOfDigitalFiles/edit" &&
            "backup" in data
        ) {
            setSelectedOptionType(data.backup);
        }

        if (
            location.pathname === "/everyday/digital-world/software/edit" &&
            ("appLocation" || "system") in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                appLocation: data["appLocation"],
                type: data["type"],
                system: data["system"],
            });
        }

        if (
            location.pathname === "/everyday/finances/taxReturns/edit" &&
            "location" in data
        ) {
            setSelectedOptionType(data.location);
        }

        if (
            location.pathname === "/everyday/finances/investments/edit" &&
            "holdingsType" in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                accountType: data["accountType"],
                holdingsType: data["holdingsType"],
                ownershipType: data["ownershipType"],
            });
        }

        if (
            location.pathname ===
                "/everyday/finances/collectibleValuables/edit" &&
            "typeOfCollectible" in data
        ) {
            setSelectedOptionType(data.typeOfCollectible);
        }

        if (
            (location.pathname === "/everyday/finances/leases/edit" ||
                location.pathname ===
                    "/everyday/finances/liabilitiesLeases/edit" ||
                location.pathname ===
                    "/everyday/real-estate/leaseAgreements/edit") &&
            "leaseType" in data
        ) {
            setSelectedOptionType(data.leaseType);
        }

        if (
            location.pathname === "/everyday/health/dnaInfo/edit" &&
            "company" in data
        ) {
            setSelectedOptionType(data.company);
        }

        if (
            location.pathname === "/everyday/health/homeHealthEquipment/edit" &&
            "item" in data
        ) {
            setSelectedOptionType(data.item);
        }

        if (
            location.pathname ===
                "/everyday/health/familyMedicalHistory/edit" &&
            "family" in data
        ) {
            setSelectedOptionType(data.family);
        }

        if (
            (location.pathname ===
                "/everyday/insurance/personalInsurance/edit" ||
                location.pathname ===
                    "/everyday/insurance/privateHealthInsurance/edit") &&
            "coverageType" in data
        ) {
            setSelectedOptionType(data.coverageType);
        }

        if (
            location.pathname ===
                "/everyday/insurance/publicHealthInsurance/edit" &&
            "publicHealthPlan" in data
        ) {
            setSelectedOptionType(data.publicHealthPlan);
        }

        if (
            location.pathname ===
                "/everyday/real-estate/realEstateHoldings/edit" &&
            "typeOfProperty" in data
        ) {
            setSelectedOptionType(data.typeOfProperty);
        }

        if (
            location.pathname ===
                "/eventual/estate-plans/jointOwnerships/edit" &&
            "property" in data
        ) {
            setSelectedOptionType(data.property);
        }

        if (
            (location.pathname ===
                "/eventual/estate-plans/giftingToIndividuals/edit" ||
                location.pathname ===
                    "/eventual/estate-plans/giftingToOrganizations/edit") &&
            "giftingStructure" in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                giftingStructure: data["giftingStructure"],
                frequency: data["frequency"],
            });
        }

        if (
            location.pathname ===
                "/eventual/senior-life-plans/selfFundingHealthCare/edit" &&
            ("revenueSources" || "options") in data
        ) {
            setSelectedOptionType({
                ...selectedOptionType,
                revenueSources: data["revenueSources"],
                options: data["options"],
            });
        }

        dispatch(updateTopicById(data));
    };

    const failCallback = (err) => {
        console.error(err);
    };

    const getDataToEdit = () => {
        if (pathname === "/supplementalSecurity(SSI)")
            pathname = "/supplementalSecurity";

        restGet(
            pathname + "/show/" + params.get("id"),
            successCallback,
            failCallback
        );
    };

    const handleChangeHidden = () => {
        setShowHiddenField(showHiddenField === true ? false : true);
    };

    const handleMultipleChoiceChange = (event, value, name) => {
        setValueMultiCheckBox({
            ...valueMultiCheckBox,
            [name]: value,
        });
    };

    useEffect(() => {
        restGet(
            "/myContacts/index",
            (data) => setContactList(data),
            (err) => console.error(err)
        );

        if (params.get("id")) getDataToEdit();
        if (params.get("tab") === "2") setTab(2);

        const preventUnload = (event) => {
            const message = "Are you sure you want to leave?";
            event.preventDefault();
            event.returnValue = message;
        };

        window.addEventListener("beforeunload", preventUnload);

        return () => {
            window.removeEventListener("beforeunload", preventUnload);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle]);

    useEffect(() => {
        if (!data) return;
        if (data !== null) {
            const mcValues = {};
            const mcFields = formFields.filter(
                ({ component }) => component === "mc"
            );
            mcFields.forEach(({ name }) => {
                mcValues[name] = data[name];
            });
            setValueMultiCheckBox(mcValues);
            setSelectedOption(data?.broker || "");
        }
    }, [data]);

    useEffect(() => {}, [nameType]);

    const currentUser = useSelector((state) => state.auth.user);
    const currentType = currentUser?.type;

    return (
        <Fragment>
            {open && (
                <ActionPanel open={open} setOpen={setOpen}>
                    <FormSubmit apiPath={apiPath} setOpen={setOpen} />
                </ActionPanel>
            )}
            <div className="flex max-w-7xl flex-col">
                <div className="w-full text-right mt-8 flex justify-between items-center">
                    <div className="text-left italic text-primary-green font-medium">
                        <p>
                            To upload one or more files to this record, click on
                            the
                        </p>
                        <p>"Attachment" tab below, and follow instructions</p>
                    </div>
                    <button
                        disabled={currentType === "readonly"}
                        onClick={(e) => {
                            e.preventDefault();
                            setOpen(true);
                        }}
                        className="group inline-flex items-center justify-center mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                    >
                        <img
                            src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/sidebar/09-to-do-list-icon-white.svg"
                            alt="TO-DO icon"
                            className="w-8 mr-2 rounded-xl group-hover:bg-primary-green transition duration-300"
                        />
                        Add a TO-DO task
                    </button>
                </div>
                <div className="py-1">
                    {/* Tabs */}

                    <div className="border-b border-gray-300">
                        <nav className="-mb-px flex space-x-8">
                            {data ? (
                                <>
                                    {tabs.map((el, idx) => (
                                        <a
                                            key={el.name}
                                            href={el.href}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTab(idx + 1);
                                            }}
                                            className={classNames(
                                                idx + 1 === tab
                                                    ? "border-primary-green text-primary-green"
                                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                "whitespace-nowrap py-4 px-1 border-b-2 font-bold text-sm"
                                            )}
                                        >
                                            {el.name}
                                        </a>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {tabs.map((el, idx) => (
                                        <a
                                            key={el.name}
                                            href={el.href}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className={classNames(
                                                idx + 1 === tab
                                                    ? "border-primary-green text-primary-green"
                                                    : "border-transparent text-gray-300 cursor-default",
                                                "whitespace-nowrap py-4 px-1 border-b-2 font-bold text-sm"
                                            )}
                                        >
                                            {el.name}
                                        </a>
                                    ))}
                                </>
                            )}
                        </nav>
                    </div>

                    {/* Description list with inline editing */}
                    {tab === 1 && (
                        <TopicForm
                            pathname={
                                params.get("id")
                                    ? pathname + "/update/" + params.get("id")
                                    : pathname + "/new"
                            }
                            reqBody={reqBody}
                            initialValues={params.get("id") ? data : null}
                            setTab={setTab}
                            setSelectedOptionType={setSelectedOptionType}
                        >
                            {pathname.includes("pets") ? (
                                <Fieldset
                                    title={
                                        params.get("id") ? "Edit a record" : ""
                                    }
                                    subtitle="topic form"
                                    type={pathname}
                                >
                                    <div
                                        className="flex items-center gap-x-2 cursor-pointer"
                                        onClick={() => toggleAccordion("info1")}
                                    >
                                        <h2 className="uppercase font-bold text-lg">
                                            Standard Information
                                        </h2>
                                        <ChevronRightIcon
                                            className={`w-6 transition duration-300 ${
                                                openAccordion === "info1"
                                                    ? "rotate-90"
                                                    : null
                                            }`}
                                        />
                                    </div>

                                    <>
                                        {nameType.length > 0 &&
                                            nameType.map(
                                                (name) =>
                                                    (selectedOptionType[
                                                        name
                                                    ] === "Other" ||
                                                        selectedOptionType[
                                                            name
                                                        ] === "other") && (
                                                        <Text
                                                            name={name}
                                                            type="text"
                                                            placeholder={`${
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .slice(1)
                                                            } Type`}
                                                        >
                                                            {`${
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .slice(1)
                                                            } Type`}
                                                            <span className="text-red-500">
                                                                {"*"}
                                                            </span>
                                                        </Text>
                                                    )
                                            )}
                                    </>

                                    <div
                                        className={`${
                                            openAccordion === "info1"
                                                ? null
                                                : "hidden"
                                        }`}
                                    >
                                        <div className="space-y-6 sm:space-y-5">
                                            {formFields
                                                .slice(0, 21)
                                                .map((el) => {
                                                    return (
                                                        <Fragment key={el.name}>
                                                            {el.component ===
                                                                "input" &&
                                                                !el.isHidden &&
                                                                el.type !==
                                                                    "date" && (
                                                                    <Text
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Text>
                                                                )}

                                                            {el.component ===
                                                                "value" && (
                                                                <NumberValueText
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    type={
                                                                        el.type
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                    placeholder={
                                                                        el.placeholder
                                                                    }
                                                                    pattern={
                                                                        el.pattern
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            el.label
                                                                        }{" "}
                                                                        <span className="text-red-500">
                                                                            {el.required &&
                                                                                "*"}
                                                                        </span>
                                                                    </p>

                                                                    {el.description ? (
                                                                        <Description
                                                                            data={
                                                                                el.description
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </NumberValueText>
                                                            )}

                                                            {/* BUG: DateText is never being used because el.isHidden is true */}
                                                            {el.component ===
                                                                "input" &&
                                                                !el.isHidden &&
                                                                el.type ===
                                                                    "date" && (
                                                                    <DateText
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </DateText>
                                                                )}

                                                            {el.component ===
                                                                "number" && (
                                                                <NumberText
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    type={
                                                                        el.type
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                    placeholder={
                                                                        el.placeholder
                                                                    }
                                                                    pattern={
                                                                        el.pattern
                                                                    }
                                                                    range={
                                                                        el.range
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            el.label
                                                                        }{" "}
                                                                        <span className="text-red-500">
                                                                            {el.required &&
                                                                                "*"}
                                                                        </span>
                                                                    </p>

                                                                    {el.description ? (
                                                                        <Description
                                                                            data={
                                                                                el.description
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </NumberText>
                                                            )}

                                                            {el.component ===
                                                                "password" && (
                                                                <Password
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    type={
                                                                        el.type
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                    placeholder={
                                                                        el.placeholder
                                                                    }
                                                                    pattern={
                                                                        el.pattern
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            el.label
                                                                        }{" "}
                                                                        <span className="text-red-500">
                                                                            {el.required &&
                                                                                "*"}
                                                                        </span>
                                                                    </p>

                                                                    {el.description ? (
                                                                        <Description
                                                                            data={
                                                                                el.description
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </Password>
                                                            )}

                                                            {el.component ===
                                                                "autocomplete" && (
                                                                <AutoCompleteComponent
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    type={
                                                                        el.type
                                                                    }
                                                                    value={
                                                                        selectedOption
                                                                    }
                                                                    onChange={
                                                                        setSelectedOption
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                    placeholder={
                                                                        el.placeholder
                                                                    }
                                                                    pattern={
                                                                        el.pattern
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            el.label
                                                                        }{" "}
                                                                        <span className="text-red-500">
                                                                            {el.required &&
                                                                                "*"}
                                                                        </span>
                                                                    </p>

                                                                    {el.description ? (
                                                                        <Description
                                                                            data={
                                                                                el.description
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </AutoCompleteComponent>
                                                            )}

                                                            {el.component ===
                                                                "input" &&
                                                                el.isHidden ===
                                                                    true &&
                                                                showHiddenField ===
                                                                    true && (
                                                                    <Text
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Text>
                                                                )}

                                                            {el.component ===
                                                                "phoneNumber" && (
                                                                <PhoneNumber
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                    placeholder={
                                                                        el.placeholder
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            el.label
                                                                        }{" "}
                                                                        <span className="text-red-500">
                                                                            {el.required &&
                                                                                "*"}
                                                                        </span>
                                                                    </p>

                                                                    {el.description ? (
                                                                        <Description
                                                                            data={
                                                                                el.description
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </PhoneNumber>
                                                            )}

                                                            {el.component ===
                                                                "textarea" && (
                                                                <Textarea
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                    placeholder={
                                                                        el.placeholder
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            el.label
                                                                        }{" "}
                                                                        <span className="text-red-500">
                                                                            {el.required &&
                                                                                "*"}
                                                                        </span>
                                                                    </p>
                                                                </Textarea>
                                                            )}

                                                            {el.component ===
                                                                "select" &&
                                                                el.name !==
                                                                    "donate" &&
                                                                el.name !==
                                                                    "receive" &&
                                                                el.name !==
                                                                    "bloodType" && (
                                                                    <SelectWithState
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        onChange={
                                                                            handleSelectChange
                                                                        }
                                                                        value={
                                                                            selectedOptionType !==
                                                                                null &&
                                                                            typeof selectedOptionType ===
                                                                                "object"
                                                                                ? selectedOptionType[
                                                                                      el
                                                                                          .name
                                                                                  ]
                                                                                : selectedOptionType
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Select...
                                                                        </option>
                                                                        {el.options.map(
                                                                            (
                                                                                option
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        selected={
                                                                                            selectedOptionType &&
                                                                                            selectedOptionType ===
                                                                                                option.value
                                                                                        }
                                                                                        key={
                                                                                            option.text
                                                                                        }
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.text
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </SelectWithState>
                                                                )}

                                                            {el.component ===
                                                                "select" &&
                                                                el.name ===
                                                                    "bloodType" && (
                                                                    <SelectWithState
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        onChange={
                                                                            handleSelectChange
                                                                        }
                                                                        value={
                                                                            selectedOptionType !==
                                                                                null &&
                                                                            typeof selectedOptionType ===
                                                                                "object"
                                                                                ? selectedOptionType[
                                                                                      el
                                                                                          .name
                                                                                  ]
                                                                                : selectedOptionType
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Select...
                                                                        </option>
                                                                        {el.options.map(
                                                                            (
                                                                                option
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        selected={
                                                                                            selectedOptionType &&
                                                                                            selectedOptionType ===
                                                                                                option.value
                                                                                        }
                                                                                        key={
                                                                                            option.text
                                                                                        }
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.text
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </SelectWithState>
                                                                )}

                                                            {el.component ===
                                                                "select" &&
                                                                el.name ===
                                                                    "receive" && (
                                                                    <SelectWithState
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        onChange={
                                                                            handleSelectChange
                                                                        }
                                                                        value={
                                                                            selectedOptionType !==
                                                                                null &&
                                                                            typeof selectedOptionType ===
                                                                                "object"
                                                                                ? selectedOptionType[
                                                                                      el
                                                                                          .name
                                                                                  ]
                                                                                : selectedOptionType
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Select...
                                                                        </option>
                                                                        {el.options.map(
                                                                            (
                                                                                option
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        selected={
                                                                                            selectedOptionType &&
                                                                                            selectedOptionType ===
                                                                                                option.value
                                                                                        }
                                                                                        key={
                                                                                            option.text
                                                                                        }
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.text
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </SelectWithState>
                                                                )}

                                                            {el.component ===
                                                                "select" &&
                                                                el.name ===
                                                                    "donate" && (
                                                                    <SelectWithState
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        onChange={
                                                                            handleSelectChange
                                                                        }
                                                                        value={
                                                                            selectedOptionType !==
                                                                                null &&
                                                                            typeof selectedOptionType ===
                                                                                "object"
                                                                                ? selectedOptionType[
                                                                                      el
                                                                                          .name
                                                                                  ]
                                                                                : selectedOptionType
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Select...
                                                                        </option>
                                                                        {el.options.map(
                                                                            (
                                                                                option
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        selected={
                                                                                            selectedOptionType &&
                                                                                            selectedOptionType ===
                                                                                                option.value
                                                                                        }
                                                                                        key={
                                                                                            option.text
                                                                                        }
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.text
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </SelectWithState>
                                                                )}

                                                            {el.component ===
                                                                "contact" &&
                                                                contactList &&
                                                                contactList.length >
                                                                    0 && (
                                                                    <Contact
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                    >
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }
                                                                        >
                                                                            Select...
                                                                        </option>
                                                                        {contactList.map(
                                                                            (
                                                                                option
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        option._id
                                                                                    }
                                                                                    value={
                                                                                        option._id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option.firstName
                                                                                    }{" "}
                                                                                    {
                                                                                        option.lastName
                                                                                    }

                                                                                    ,{" "}
                                                                                    {
                                                                                        option.contactType
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </Contact>
                                                                )}

                                                            {el.component ===
                                                                "checkbox" && (
                                                                <Checkbox
                                                                    label={
                                                                        el.label
                                                                    }
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    title={
                                                                        el.title
                                                                    }
                                                                    autoComplete={
                                                                        el.autoComplete
                                                                    }
                                                                    required={
                                                                        el.required
                                                                    }
                                                                />
                                                            )}

                                                            {el.component ===
                                                                "showHiddenCheckbox" && (
                                                                <Checkbox
                                                                    title={
                                                                        el.title
                                                                    }
                                                                    name="showHiddenField"
                                                                    onClick={
                                                                        handleChangeHidden
                                                                    }
                                                                />
                                                            )}

                                                            {el.component ===
                                                                "mc" && (
                                                                <MultipleChoice
                                                                    label={
                                                                        el.label
                                                                    }
                                                                    name={
                                                                        el.name
                                                                    }
                                                                    values={
                                                                        el.values
                                                                    }
                                                                    onChange={(
                                                                        event,
                                                                        value
                                                                    ) =>
                                                                        handleMultipleChoiceChange(
                                                                            event,
                                                                            value,
                                                                            el.name
                                                                        )
                                                                    }
                                                                    editValues={
                                                                        valueMultiCheckBox[
                                                                            el
                                                                                .name
                                                                        ]
                                                                    }
                                                                />
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                        </div>

                                        {selectedOptionType[
                                            "collarWithIdTags"
                                        ] === "Yes" && (
                                            <div className="sm:pt-5 space-y-6 sm:space-y-5">
                                                {formFields
                                                    .slice(21, 22)
                                                    .map((el) => {
                                                        return (
                                                            <Fragment
                                                                key={el.name}
                                                            >
                                                                {el.component ===
                                                                    "input" &&
                                                                    !el.isHidden &&
                                                                    el.type !==
                                                                        "date" && (
                                                                        <Text
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            pattern={
                                                                                el.pattern
                                                                            }
                                                                        >
                                                                            <p>
                                                                                {
                                                                                    el.label
                                                                                }{" "}
                                                                                <span className="text-red-500">
                                                                                    {el.required &&
                                                                                        "*"}
                                                                                </span>
                                                                            </p>

                                                                            {el.description ? (
                                                                                <Description
                                                                                    data={
                                                                                        el.description
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </Text>
                                                                    )}

                                                                {el.component ===
                                                                    "value" && (
                                                                    <NumberValueText
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </NumberValueText>
                                                                )}

                                                                {/* BUG: DateText is never being used because el.isHidden is true */}
                                                                {el.component ===
                                                                    "input" &&
                                                                    !el.isHidden &&
                                                                    el.type ===
                                                                        "date" && (
                                                                        <DateText
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            pattern={
                                                                                el.pattern
                                                                            }
                                                                        >
                                                                            <p>
                                                                                {
                                                                                    el.label
                                                                                }{" "}
                                                                                <span className="text-red-500">
                                                                                    {el.required &&
                                                                                        "*"}
                                                                                </span>
                                                                            </p>

                                                                            {el.description ? (
                                                                                <Description
                                                                                    data={
                                                                                        el.description
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </DateText>
                                                                    )}

                                                                {el.component ===
                                                                    "number" && (
                                                                    <NumberText
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                        range={
                                                                            el.range
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </NumberText>
                                                                )}

                                                                {el.component ===
                                                                    "password" && (
                                                                    <Password
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </Password>
                                                                )}

                                                                {el.component ===
                                                                    "autocomplete" && (
                                                                    <AutoCompleteComponent
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        type={
                                                                            el.type
                                                                        }
                                                                        value={
                                                                            selectedOption
                                                                        }
                                                                        onChange={
                                                                            setSelectedOption
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                        pattern={
                                                                            el.pattern
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </AutoCompleteComponent>
                                                                )}

                                                                {el.component ===
                                                                    "input" &&
                                                                    el.isHidden ===
                                                                        true &&
                                                                    showHiddenField ===
                                                                        true && (
                                                                        <Text
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            pattern={
                                                                                el.pattern
                                                                            }
                                                                        >
                                                                            <p>
                                                                                {
                                                                                    el.label
                                                                                }{" "}
                                                                                <span className="text-red-500">
                                                                                    {el.required &&
                                                                                        "*"}
                                                                                </span>
                                                                            </p>

                                                                            {el.description ? (
                                                                                <Description
                                                                                    data={
                                                                                        el.description
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </Text>
                                                                    )}

                                                                {el.component ===
                                                                    "phoneNumber" && (
                                                                    <PhoneNumber
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>

                                                                        {el.description ? (
                                                                            <Description
                                                                                data={
                                                                                    el.description
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </PhoneNumber>
                                                                )}

                                                                {el.component ===
                                                                    "textarea" && (
                                                                    <Textarea
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                        placeholder={
                                                                            el.placeholder
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {
                                                                                el.label
                                                                            }{" "}
                                                                            <span className="text-red-500">
                                                                                {el.required &&
                                                                                    "*"}
                                                                            </span>
                                                                        </p>
                                                                    </Textarea>
                                                                )}

                                                                {el.component ===
                                                                    "select" &&
                                                                    el.name !==
                                                                        "donate" &&
                                                                    el.name !==
                                                                        "receive" &&
                                                                    el.name !==
                                                                        "bloodType" && (
                                                                        <SelectWithState
                                                                            label={
                                                                                el.label
                                                                            }
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            onChange={
                                                                                handleSelectChange
                                                                            }
                                                                            value={
                                                                                selectedOptionType !==
                                                                                    null &&
                                                                                typeof selectedOptionType ===
                                                                                    "object"
                                                                                    ? selectedOptionType[
                                                                                          el
                                                                                              .name
                                                                                      ]
                                                                                    : selectedOptionType
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    ""
                                                                                }
                                                                            >
                                                                                Select...
                                                                            </option>
                                                                            {el.options.map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            selected={
                                                                                                selectedOptionType &&
                                                                                                selectedOptionType ===
                                                                                                    option.value
                                                                                            }
                                                                                            key={
                                                                                                option.text
                                                                                            }
                                                                                            value={
                                                                                                option.value
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                option.text
                                                                                            }
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </SelectWithState>
                                                                    )}

                                                                {el.component ===
                                                                    "select" &&
                                                                    el.name ===
                                                                        "bloodType" && (
                                                                        <SelectWithState
                                                                            label={
                                                                                el.label
                                                                            }
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            onChange={
                                                                                handleSelectChange
                                                                            }
                                                                            value={
                                                                                selectedOptionType !==
                                                                                    null &&
                                                                                typeof selectedOptionType ===
                                                                                    "object"
                                                                                    ? selectedOptionType[
                                                                                          el
                                                                                              .name
                                                                                      ]
                                                                                    : selectedOptionType
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    ""
                                                                                }
                                                                            >
                                                                                Select...
                                                                            </option>
                                                                            {el.options.map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            selected={
                                                                                                selectedOptionType &&
                                                                                                selectedOptionType ===
                                                                                                    option.value
                                                                                            }
                                                                                            key={
                                                                                                option.text
                                                                                            }
                                                                                            value={
                                                                                                option.value
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                option.text
                                                                                            }
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </SelectWithState>
                                                                    )}

                                                                {el.component ===
                                                                    "select" &&
                                                                    el.name ===
                                                                        "receive" && (
                                                                        <SelectWithState
                                                                            label={
                                                                                el.label
                                                                            }
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            onChange={
                                                                                handleSelectChange
                                                                            }
                                                                            value={
                                                                                selectedOptionType !==
                                                                                    null &&
                                                                                typeof selectedOptionType ===
                                                                                    "object"
                                                                                    ? selectedOptionType[
                                                                                          el
                                                                                              .name
                                                                                      ]
                                                                                    : selectedOptionType
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    ""
                                                                                }
                                                                            >
                                                                                Select...
                                                                            </option>
                                                                            {el.options.map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            selected={
                                                                                                selectedOptionType &&
                                                                                                selectedOptionType ===
                                                                                                    option.value
                                                                                            }
                                                                                            key={
                                                                                                option.text
                                                                                            }
                                                                                            value={
                                                                                                option.value
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                option.text
                                                                                            }
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </SelectWithState>
                                                                    )}

                                                                {el.component ===
                                                                    "select" &&
                                                                    el.name ===
                                                                        "donate" && (
                                                                        <SelectWithState
                                                                            label={
                                                                                el.label
                                                                            }
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                            onChange={
                                                                                handleSelectChange
                                                                            }
                                                                            value={
                                                                                selectedOptionType !==
                                                                                    null &&
                                                                                typeof selectedOptionType ===
                                                                                    "object"
                                                                                    ? selectedOptionType[
                                                                                          el
                                                                                              .name
                                                                                      ]
                                                                                    : selectedOptionType
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    ""
                                                                                }
                                                                            >
                                                                                Select...
                                                                            </option>
                                                                            {el.options.map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            selected={
                                                                                                selectedOptionType &&
                                                                                                selectedOptionType ===
                                                                                                    option.value
                                                                                            }
                                                                                            key={
                                                                                                option.text
                                                                                            }
                                                                                            value={
                                                                                                option.value
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                option.text
                                                                                            }
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </SelectWithState>
                                                                    )}

                                                                {el.component ===
                                                                    "contact" &&
                                                                    contactList &&
                                                                    contactList.length >
                                                                        0 && (
                                                                        <Contact
                                                                            label={
                                                                                el.label
                                                                            }
                                                                            name={
                                                                                el.name
                                                                            }
                                                                            type={
                                                                                el.type
                                                                            }
                                                                            autoComplete={
                                                                                el.autoComplete
                                                                            }
                                                                            required={
                                                                                el.required
                                                                            }
                                                                            placeholder={
                                                                                el.placeholder
                                                                            }
                                                                        >
                                                                            <option
                                                                                value={
                                                                                    ""
                                                                                }
                                                                            >
                                                                                Select...
                                                                            </option>
                                                                            {contactList.map(
                                                                                (
                                                                                    option
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            option._id
                                                                                        }
                                                                                        value={
                                                                                            option._id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.firstName
                                                                                        }{" "}
                                                                                        {
                                                                                            option.lastName
                                                                                        }

                                                                                        ,{" "}
                                                                                        {
                                                                                            option.contactType
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </Contact>
                                                                    )}

                                                                {el.component ===
                                                                    "checkbox" && (
                                                                    <Checkbox
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        title={
                                                                            el.title
                                                                        }
                                                                        autoComplete={
                                                                            el.autoComplete
                                                                        }
                                                                        required={
                                                                            el.required
                                                                        }
                                                                    />
                                                                )}

                                                                {el.component ===
                                                                    "showHiddenCheckbox" && (
                                                                    <Checkbox
                                                                        title={
                                                                            el.title
                                                                        }
                                                                        name="showHiddenField"
                                                                        onClick={
                                                                            handleChangeHidden
                                                                        }
                                                                    />
                                                                )}

                                                                {el.component ===
                                                                    "mc" && (
                                                                    <MultipleChoice
                                                                        label={
                                                                            el.label
                                                                        }
                                                                        name={
                                                                            el.name
                                                                        }
                                                                        values={
                                                                            el.values
                                                                        }
                                                                        onChange={(
                                                                            event,
                                                                            value
                                                                        ) =>
                                                                            handleMultipleChoiceChange(
                                                                                event,
                                                                                value,
                                                                                el.name
                                                                            )
                                                                        }
                                                                        editValues={
                                                                            valueMultiCheckBox[
                                                                                el
                                                                                    .name
                                                                            ]
                                                                        }
                                                                    />
                                                                )}
                                                            </Fragment>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-300 sm:pt-5">
                                        <div
                                            className="flex items-center gap-x-2 cursor-pointer"
                                            onClick={() =>
                                                toggleAccordion("info2")
                                            }
                                        >
                                            <h2 className="uppercase font-bold text-lg">
                                                Additional Details
                                            </h2>
                                            <ChevronRightIcon
                                                className={`w-6 transition duration-300 ${
                                                    openAccordion === "info2"
                                                        ? "rotate-90"
                                                        : null
                                                }`}
                                            />
                                        </div>

                                        <p className="sm:col-span-2 pt-2 sm:pt-0">
                                            For more detailed information or If
                                            you have any other animal or type of
                                            pet, you can add information to the
                                            “Additional Details” section.
                                        </p>
                                    </div>

                                    <div
                                        className={`${
                                            openAccordion === "info2"
                                                ? null
                                                : "hidden"
                                        } space-y-6 sm:space-y-5`}
                                    >
                                        {formFields.slice(22).map((el) => {
                                            return (
                                                <Fragment key={el.name}>
                                                    {el.component === "input" &&
                                                        !el.isHidden &&
                                                        el.type !== "date" && (
                                                            <Text
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                pattern={
                                                                    el.pattern
                                                                }
                                                            >
                                                                <p>
                                                                    {el.label}{" "}
                                                                    <span className="text-red-500">
                                                                        {el.required &&
                                                                            "*"}
                                                                    </span>
                                                                </p>

                                                                {el.description ? (
                                                                    <Description
                                                                        data={
                                                                            el.description
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </Text>
                                                        )}

                                                    {el.component ===
                                                        "value" && (
                                                        <NumberValueText
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </NumberValueText>
                                                    )}

                                                    {/* BUG: DateText is never being used because el.isHidden is true */}
                                                    {el.component === "input" &&
                                                        !el.isHidden &&
                                                        el.type === "date" && (
                                                            <DateText
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                pattern={
                                                                    el.pattern
                                                                }
                                                            >
                                                                <p>
                                                                    {el.label}{" "}
                                                                    <span className="text-red-500">
                                                                        {el.required &&
                                                                            "*"}
                                                                    </span>
                                                                </p>

                                                                {el.description ? (
                                                                    <Description
                                                                        data={
                                                                            el.description
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </DateText>
                                                        )}

                                                    {el.component ===
                                                        "number" && (
                                                        <NumberText
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                            range={el.range}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </NumberText>
                                                    )}

                                                    {el.component ===
                                                        "password" && (
                                                        <Password
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </Password>
                                                    )}

                                                    {el.component ===
                                                        "autocomplete" && (
                                                        <AutoCompleteComponent
                                                            name={el.name}
                                                            type={el.type}
                                                            value={
                                                                selectedOption
                                                            }
                                                            onChange={
                                                                setSelectedOption
                                                            }
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </AutoCompleteComponent>
                                                    )}

                                                    {el.component === "input" &&
                                                        el.isHidden === true &&
                                                        showHiddenField ===
                                                            true && (
                                                            <Text
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                pattern={
                                                                    el.pattern
                                                                }
                                                            >
                                                                <p>
                                                                    {el.label}{" "}
                                                                    <span className="text-red-500">
                                                                        {el.required &&
                                                                            "*"}
                                                                    </span>
                                                                </p>

                                                                {el.description ? (
                                                                    <Description
                                                                        data={
                                                                            el.description
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </Text>
                                                        )}

                                                    {el.component ===
                                                        "phoneNumber" && (
                                                        <PhoneNumber
                                                            name={el.name}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </PhoneNumber>
                                                    )}

                                                    {el.component ===
                                                        "textarea" && (
                                                        <Textarea
                                                            name={el.name}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>
                                                        </Textarea>
                                                    )}

                                                    {el.component ===
                                                        "select" &&
                                                        el.name !== "donate" &&
                                                        el.name !== "receive" &&
                                                        el.name !==
                                                            "bloodType" && (
                                                            <SelectWithState
                                                                label={el.label}
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                onChange={
                                                                    handleSelectChange
                                                                }
                                                                value={
                                                                    selectedOptionType !==
                                                                        null &&
                                                                    typeof selectedOptionType ===
                                                                        "object"
                                                                        ? selectedOptionType[
                                                                              el
                                                                                  .name
                                                                          ]
                                                                        : selectedOptionType
                                                                }
                                                            >
                                                                <option
                                                                    value={""}
                                                                >
                                                                    Select...
                                                                </option>
                                                                {el.options.map(
                                                                    (
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                selected={
                                                                                    selectedOptionType &&
                                                                                    selectedOptionType ===
                                                                                        option.value
                                                                                }
                                                                                key={
                                                                                    option.text
                                                                                }
                                                                                value={
                                                                                    option.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.text
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectWithState>
                                                        )}

                                                    {el.component ===
                                                        "select" &&
                                                        el.name ===
                                                            "bloodType" && (
                                                            <SelectWithState
                                                                label={el.label}
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                onChange={
                                                                    handleSelectChange
                                                                }
                                                                value={
                                                                    selectedOptionType !==
                                                                        null &&
                                                                    typeof selectedOptionType ===
                                                                        "object"
                                                                        ? selectedOptionType[
                                                                              el
                                                                                  .name
                                                                          ]
                                                                        : selectedOptionType
                                                                }
                                                            >
                                                                <option
                                                                    value={""}
                                                                >
                                                                    Select...
                                                                </option>
                                                                {el.options.map(
                                                                    (
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                selected={
                                                                                    selectedOptionType &&
                                                                                    selectedOptionType ===
                                                                                        option.value
                                                                                }
                                                                                key={
                                                                                    option.text
                                                                                }
                                                                                value={
                                                                                    option.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.text
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectWithState>
                                                        )}

                                                    {el.component ===
                                                        "select" &&
                                                        el.name ===
                                                            "receive" && (
                                                            <SelectWithState
                                                                label={el.label}
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                onChange={
                                                                    handleSelectChange
                                                                }
                                                                value={
                                                                    selectedOptionType !==
                                                                        null &&
                                                                    typeof selectedOptionType ===
                                                                        "object"
                                                                        ? selectedOptionType[
                                                                              el
                                                                                  .name
                                                                          ]
                                                                        : selectedOptionType
                                                                }
                                                            >
                                                                <option
                                                                    value={""}
                                                                >
                                                                    Select...
                                                                </option>
                                                                {el.options.map(
                                                                    (
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                selected={
                                                                                    selectedOptionType &&
                                                                                    selectedOptionType ===
                                                                                        option.value
                                                                                }
                                                                                key={
                                                                                    option.text
                                                                                }
                                                                                value={
                                                                                    option.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.text
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectWithState>
                                                        )}

                                                    {el.component ===
                                                        "select" &&
                                                        el.name ===
                                                            "donate" && (
                                                            <SelectWithState
                                                                label={el.label}
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                                onChange={
                                                                    handleSelectChange
                                                                }
                                                                value={
                                                                    selectedOptionType !==
                                                                        null &&
                                                                    typeof selectedOptionType ===
                                                                        "object"
                                                                        ? selectedOptionType[
                                                                              el
                                                                                  .name
                                                                          ]
                                                                        : selectedOptionType
                                                                }
                                                            >
                                                                <option
                                                                    value={""}
                                                                >
                                                                    Select...
                                                                </option>
                                                                {el.options.map(
                                                                    (
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                selected={
                                                                                    selectedOptionType &&
                                                                                    selectedOptionType ===
                                                                                        option.value
                                                                                }
                                                                                key={
                                                                                    option.text
                                                                                }
                                                                                value={
                                                                                    option.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.text
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectWithState>
                                                        )}

                                                    {el.component ===
                                                        "contact" &&
                                                        contactList &&
                                                        contactList.length >
                                                            0 && (
                                                            <Contact
                                                                label={el.label}
                                                                name={el.name}
                                                                type={el.type}
                                                                autoComplete={
                                                                    el.autoComplete
                                                                }
                                                                required={
                                                                    el.required
                                                                }
                                                                placeholder={
                                                                    el.placeholder
                                                                }
                                                            >
                                                                <option
                                                                    value={""}
                                                                >
                                                                    Select...
                                                                </option>
                                                                {contactList.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                option._id
                                                                            }
                                                                            value={
                                                                                option._id
                                                                            }
                                                                        >
                                                                            {
                                                                                option.firstName
                                                                            }{" "}
                                                                            {
                                                                                option.lastName
                                                                            }
                                                                            ,{" "}
                                                                            {
                                                                                option.contactType
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </Contact>
                                                        )}

                                                    {el.component ===
                                                        "checkbox" && (
                                                        <Checkbox
                                                            label={el.label}
                                                            name={el.name}
                                                            title={el.title}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                        />
                                                    )}

                                                    {el.component ===
                                                        "showHiddenCheckbox" && (
                                                        <Checkbox
                                                            title={el.title}
                                                            name="showHiddenField"
                                                            onClick={
                                                                handleChangeHidden
                                                            }
                                                        />
                                                    )}

                                                    {el.component === "mc" && (
                                                        <MultipleChoice
                                                            label={el.label}
                                                            name={el.name}
                                                            values={el.values}
                                                            onChange={(
                                                                event,
                                                                value
                                                            ) =>
                                                                handleMultipleChoiceChange(
                                                                    event,
                                                                    value,
                                                                    el.name
                                                                )
                                                            }
                                                            editValues={
                                                                valueMultiCheckBox[
                                                                    el.name
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                    </div>
                                </Fieldset>
                            ) : (
                                <Fieldset
                                    title={
                                        params.get("id") ? "Edit a record" : ""
                                    }
                                    subtitle="topic form"
                                    type={pathname}
                                >
                                    <>
                                        {nameType.length > 0 &&
                                            nameType.map(
                                                (name) =>
                                                    (selectedOptionType[
                                                        name
                                                    ] === "Other" ||
                                                        selectedOptionType[
                                                            name
                                                        ] === "other") && (
                                                        <Text
                                                            name={name}
                                                            type="text"
                                                            placeholder={`${
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .slice(1)
                                                            } Type`}
                                                        >
                                                            {`${
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                name
                                                                    .slice(
                                                                        0,
                                                                        -4
                                                                    )
                                                                    .slice(1)
                                                            } Type`}
                                                            <span className="text-red-500">
                                                                {"*"}
                                                            </span>
                                                        </Text>
                                                    )
                                            )}
                                    </>
                                    {formFields.map((el) => {
                                        return (
                                            <Fragment key={el.name}>
                                                {el.component === "input" &&
                                                    !el.isHidden &&
                                                    el.type !== "date" && (
                                                        <Text
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </Text>
                                                    )}

                                                {el.component === "value" && (
                                                    <NumberValueText
                                                        name={el.name}
                                                        type={el.type}
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                        placeholder={
                                                            el.placeholder
                                                        }
                                                        pattern={el.pattern}
                                                    >
                                                        <p>
                                                            {el.label}{" "}
                                                            <span className="text-red-500">
                                                                {el.required &&
                                                                    "*"}
                                                            </span>
                                                        </p>

                                                        {el.description ? (
                                                            <Description
                                                                data={
                                                                    el.description
                                                                }
                                                            />
                                                        ) : null}
                                                    </NumberValueText>
                                                )}

                                                {/* BUG: DateText is never being used because el.isHidden is true */}
                                                {el.component === "input" &&
                                                    !el.isHidden &&
                                                    el.type === "date" && (
                                                        <DateText
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </DateText>
                                                    )}

                                                {el.component === "number" && (
                                                    <NumberText
                                                        name={el.name}
                                                        type={el.type}
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                        placeholder={
                                                            el.placeholder
                                                        }
                                                        pattern={el.pattern}
                                                        range={el.range}
                                                    >
                                                        <p>
                                                            {el.label}{" "}
                                                            <span className="text-red-500">
                                                                {el.required &&
                                                                    "*"}
                                                            </span>
                                                        </p>

                                                        {el.description ? (
                                                            <Description
                                                                data={
                                                                    el.description
                                                                }
                                                            />
                                                        ) : null}
                                                    </NumberText>
                                                )}

                                                {el.component ===
                                                    "password" && (
                                                    <Password
                                                        name={el.name}
                                                        type={el.type}
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                        placeholder={
                                                            el.placeholder
                                                        }
                                                        pattern={el.pattern}
                                                    >
                                                        <p>
                                                            {el.label}{" "}
                                                            <span className="text-red-500">
                                                                {el.required &&
                                                                    "*"}
                                                            </span>
                                                        </p>

                                                        {el.description ? (
                                                            <Description
                                                                data={
                                                                    el.description
                                                                }
                                                            />
                                                        ) : null}
                                                    </Password>
                                                )}

                                                {el.component ===
                                                    "autocomplete" && (
                                                    <AutoCompleteComponent
                                                        name={el.name}
                                                        type={el.type}
                                                        value={selectedOption}
                                                        onChange={
                                                            setSelectedOption
                                                        }
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                        placeholder={
                                                            el.placeholder
                                                        }
                                                        pattern={el.pattern}
                                                    >
                                                        <p>
                                                            {el.label}{" "}
                                                            <span className="text-red-500">
                                                                {el.required &&
                                                                    "*"}
                                                            </span>
                                                        </p>

                                                        {el.description ? (
                                                            <Description
                                                                data={
                                                                    el.description
                                                                }
                                                            />
                                                        ) : null}
                                                    </AutoCompleteComponent>
                                                )}

                                                {el.component === "input" &&
                                                    el.isHidden === true &&
                                                    showHiddenField ===
                                                        true && (
                                                        <Text
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            pattern={el.pattern}
                                                        >
                                                            <p>
                                                                {el.label}{" "}
                                                                <span className="text-red-500">
                                                                    {el.required &&
                                                                        "*"}
                                                                </span>
                                                            </p>

                                                            {el.description ? (
                                                                <Description
                                                                    data={
                                                                        el.description
                                                                    }
                                                                />
                                                            ) : null}
                                                        </Text>
                                                    )}

                                                {el.component ===
                                                    "phoneNumber" && (
                                                    <PhoneNumber
                                                        name={el.name}
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                        placeholder={
                                                            el.placeholder
                                                        }
                                                    >
                                                        <p>
                                                            {el.label}{" "}
                                                            <span className="text-red-500">
                                                                {el.required &&
                                                                    "*"}
                                                            </span>
                                                        </p>

                                                        {el.description ? (
                                                            <Description
                                                                data={
                                                                    el.description
                                                                }
                                                            />
                                                        ) : null}
                                                    </PhoneNumber>
                                                )}

                                                {el.component ===
                                                    "textarea" && (
                                                    <Textarea
                                                        name={el.name}
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                        placeholder={
                                                            el.placeholder
                                                        }
                                                    >
                                                        <p>
                                                            {el.label}{" "}
                                                            <span className="text-red-500">
                                                                {el.required &&
                                                                    "*"}
                                                            </span>
                                                        </p>
                                                    </Textarea>
                                                )}

                                                {el.component === "select" &&
                                                    el.name !== "donate" &&
                                                    el.name !== "receive" &&
                                                    el.name !== "bloodType" && (
                                                        <SelectWithState
                                                            label={el.label}
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            onChange={
                                                                handleSelectChange
                                                            }
                                                            value={
                                                                selectedOptionType !==
                                                                    null &&
                                                                typeof selectedOptionType ===
                                                                    "object"
                                                                    ? selectedOptionType[
                                                                          el
                                                                              .name
                                                                      ]
                                                                    : selectedOptionType
                                                            }
                                                        >
                                                            <option value={""}>
                                                                Select...
                                                            </option>
                                                            {el.options.map(
                                                                (option) => {
                                                                    return (
                                                                        <option
                                                                            selected={
                                                                                selectedOptionType &&
                                                                                selectedOptionType ===
                                                                                    option.value
                                                                            }
                                                                            key={
                                                                                option.text
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.text
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </SelectWithState>
                                                    )}

                                                {el.component === "select" &&
                                                    el.name === "bloodType" && (
                                                        <SelectWithState
                                                            label={el.label}
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            onChange={
                                                                handleSelectChange
                                                            }
                                                            value={
                                                                selectedOptionType !==
                                                                    null &&
                                                                typeof selectedOptionType ===
                                                                    "object"
                                                                    ? selectedOptionType[
                                                                          el
                                                                              .name
                                                                      ]
                                                                    : selectedOptionType
                                                            }
                                                        >
                                                            <option value={""}>
                                                                Select...
                                                            </option>
                                                            {el.options.map(
                                                                (option) => {
                                                                    return (
                                                                        <option
                                                                            selected={
                                                                                selectedOptionType &&
                                                                                selectedOptionType ===
                                                                                    option.value
                                                                            }
                                                                            key={
                                                                                option.text
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.text
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </SelectWithState>
                                                    )}

                                                {el.component === "select" &&
                                                    el.name === "receive" && (
                                                        <SelectWithState
                                                            label={el.label}
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            onChange={
                                                                handleSelectChange
                                                            }
                                                            value={
                                                                selectedOptionType !==
                                                                    null &&
                                                                typeof selectedOptionType ===
                                                                    "object"
                                                                    ? selectedOptionType[
                                                                          el
                                                                              .name
                                                                      ]
                                                                    : selectedOptionType
                                                            }
                                                        >
                                                            <option value={""}>
                                                                Select...
                                                            </option>
                                                            {el.options.map(
                                                                (option) => {
                                                                    return (
                                                                        <option
                                                                            selected={
                                                                                selectedOptionType &&
                                                                                selectedOptionType ===
                                                                                    option.value
                                                                            }
                                                                            key={
                                                                                option.text
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.text
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </SelectWithState>
                                                    )}

                                                {el.component === "select" &&
                                                    el.name === "donate" && (
                                                        <SelectWithState
                                                            label={el.label}
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                            onChange={
                                                                handleSelectChange
                                                            }
                                                            value={
                                                                selectedOptionType !==
                                                                    null &&
                                                                typeof selectedOptionType ===
                                                                    "object"
                                                                    ? selectedOptionType[
                                                                          el
                                                                              .name
                                                                      ]
                                                                    : selectedOptionType
                                                            }
                                                        >
                                                            <option value={""}>
                                                                Select...
                                                            </option>
                                                            {el.options.map(
                                                                (option) => {
                                                                    return (
                                                                        <option
                                                                            selected={
                                                                                selectedOptionType &&
                                                                                selectedOptionType ===
                                                                                    option.value
                                                                            }
                                                                            key={
                                                                                option.text
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.text
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </SelectWithState>
                                                    )}

                                                {el.component === "contact" &&
                                                    contactList &&
                                                    contactList.length > 0 && (
                                                        <Contact
                                                            label={el.label}
                                                            name={el.name}
                                                            type={el.type}
                                                            autoComplete={
                                                                el.autoComplete
                                                            }
                                                            required={
                                                                el.required
                                                            }
                                                            placeholder={
                                                                el.placeholder
                                                            }
                                                        >
                                                            <option value={""}>
                                                                Select...
                                                            </option>
                                                            {contactList.map(
                                                                (option) => (
                                                                    <option
                                                                        key={
                                                                            option._id
                                                                        }
                                                                        value={
                                                                            option._id
                                                                        }
                                                                    >
                                                                        {
                                                                            option.firstName
                                                                        }{" "}
                                                                        {
                                                                            option.lastName
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            option.contactType
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </Contact>
                                                    )}

                                                {el.component ===
                                                    "checkbox" && (
                                                    <Checkbox
                                                        label={el.label}
                                                        name={el.name}
                                                        title={el.title}
                                                        autoComplete={
                                                            el.autoComplete
                                                        }
                                                        required={el.required}
                                                    />
                                                )}

                                                {el.component ===
                                                    "showHiddenCheckbox" && (
                                                    <Checkbox
                                                        title={el.title}
                                                        name="showHiddenField"
                                                        onClick={
                                                            handleChangeHidden
                                                        }
                                                    />
                                                )}

                                                {el.component === "mc" && (
                                                    <MultipleChoice
                                                        label={el.label}
                                                        name={el.name}
                                                        values={el.values}
                                                        onChange={(
                                                            event,
                                                            value
                                                        ) =>
                                                            handleMultipleChoiceChange(
                                                                event,
                                                                value,
                                                                el.name
                                                            )
                                                        }
                                                        editValues={
                                                            valueMultiCheckBox[
                                                                el.name
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </Fieldset>
                            )}
                        </TopicForm>
                    )}

                    {tab === 2 && data && (
                        <div className="space-y-8 divide-y divide-gray-300 mb-6 -mt-2">
                            <div className="space-y-8 sm:space-y-5">
                                <Fieldset
                                    title={"Attach files to the record"}
                                    subtitle={
                                        "Maximum files to be attached are 10."
                                    }
                                >
                                    <FileUpload
                                        pathname={pathname}
                                        data={data}
                                    />
                                </Fieldset>

                                <div className="flex justify-end">
                                    <Link
                                        to={route(location.pathname)}
                                        className="mx-1 px-4 py-2 text-sm font-medium bg-white hover:bg-gray-400 disabled:bg-gray-400 text-black hover:text-white disabled:text-white border-2 border-gray-400 focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                                    >
                                        Cancel
                                    </Link>

                                    <Link
                                        to={route(location.pathname)}
                                        className="mx-1 px-4 py-2 text-sm font-medium bg-primary-green hover:bg-white disabled:bg-white text-white hover:text-primary-green disabled:text-primary-green border-2 border-primary-green focus:outline-none rounded-md disabled:cursor-not-allowed transition duration-300"
                                    >
                                        Save
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

