export const personalInsuranceData = {
  formTitle: "Add or edit personal insurance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/personalInsurance",
  formFields: [
    {
      component: "select",
      label: "Coverage type",
      name: "coverageType",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Burial", text: "Burial" },
        { value: "Gap", text: "Gap" },
        { value: "Identification Theft", text: "Identification Theft" },
        { value: "Pet", text: "Pet" },
        { value: "Term Life", text: "Term Life" },
        { value: "Travel", text: "Travel" },
        { value: "Wedding", text: "Wedding" },
        { value: "Whole Life", text: "Whole Life" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Insurance company",
      name: "company",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Policy owner",
      name: "policyOwner",
      type: "text",
      description:
        "The insured person or a representative of the insured (such as a spouse or trustee) should the insured become disabled.",
    },
    {
      component: "input",
      label: "Name of insured",
      name: "insured",
      type: "text",
      description: "The person that the insurance policy covers.",
    },
    {
      component: "input",
      label: "Name of person who receives copy of bills if I am disabled",
      name: "copyPerson",
      type: "text",
      description:
        "This ensures that any required policy premiums are paid on time in the event of death/disability.",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "value",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "number",
      colObject: true,
      description:
        "The maximum amount of money an insurance company will pay for a covered claim.",
    },
    {
      component: "value",
      label: "Face value",
      name: "faceValue",
      type: "number",
      required: true,
      colObject: true,
      description:
        "The dollar amount equated to the worth of your life insurance policy. It’s the amount of money given to the beneficiary when the policy expires.",
    },
    {
      component: "value",
      label: "Most recent surrender value",
      name: "surrenderValue",
      type: "number",
      description:
        "The actual sum of money the policyholder receives if you or an approved party cancels the policy. It’s also known as a “surrender cash value” or “annuity surrender value.”",
    },
    {
      component: "input",
      label: "Beneficiary",
      name: "beneficiary",
      type: "text",
      description:
        "The person, group of persons, or entity that receives benefits upon your death. A beneficiary can be a person, a trust you’ve established, a charity, or your estate.",
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer service phone number",
      name: "csPhone",
    },
    {
      component: "input",
      label: "Broker name",
      name: "broker",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Broker phone number",
      name: "brokerPhone",
    },
    {
      component: "input",
      label: "Broker email",
      name: "brokerEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Policy number",
      name: "policyNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "number",
    },
    {
      component: "input",
      label: "Annual premium",
      name: "annualPremium",
      type: "text",
    },
    {
      component: "input",
      label: "Issue date",
      name: "issueDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of most recent review of this policy",
      name: "recentReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of next review",
      name: "nextReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "expirationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Policy paid by employer",
      name: "paidPolicy",
      type: "text",
    },
    {
      component: "input",
      label: "Employer contact name",
      name: "employer",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Employer contact phone number",
      name: "employerPhone",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
