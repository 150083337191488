export const merchantAccountsData = {
  formTitle: "Add or edit merchant account",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/merchantAccounts",
  formFields: [
    {
      component: "input",
      label: "Name of merchant",
      name: "merchantName",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "mc",
      label: "Merchant type",
      name: "merchant",
      values: [
        "Retail store",
        "Web-based / internet",
        "Wholesaler",
        "Other",
      ],
    },
    {
      component: "mc",
      label: "Sales transaction methods",
      name: "transaction",
      values: [
        "Cash",
        "Charge account",
        "Check",
        "Merchant’s credit card",
        "Other credit card",
        "Other",
      ],
    },
    {
      component: "input",
      label: "Customer service representative",
      name: "csRep",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer service phone",
      name: "csPhone",
    },
    {
      component: "input",
      label: "Customer service email",
      name: "csEmail",
      type: "email",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
      description:
        "Be sure to share the exact name(s) on each of your financial accounts along with access information with your loved ones.",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "number",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "number",
    },
    {
      component: "input",
      label: "Annual fee",
      name: "fee",
      type: "number",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
