export const investmentAccountData = {
  formTitle: 'Add or edit investment account',
  formSubtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  pathname: '/investmentAccount',
  formFields: [
    {
      component: 'select',
      label: 'Account type',
      name: 'type',
      type: 'text',
      required: true,
      colObject: true,
      options: [
        { value: '401(k)', text: '401(k)' },
        { value: '529 Savings Plan', text: '529 Savings Plan' },
        {
          value: 'Coverdell Education Savings Plan',
          text: 'Coverdell Education Savings Plan',
        },
        {
          value: 'Health Savings Account (HSA)',
          text: 'Health Savings Account (HSA)',
        },
        { value: 'Individual TOD Account', text: 'Individual TOD Account' },
        { value: 'IRA:  Custdial', text: 'IRA: Custodial' },
        { value: 'IRA:  Roh IRA', text: 'IRA: Roth IRA' },
        { value: 'IRA: SEP IRA', text: 'IRA: SEP IRA' },
        { value: 'IRA:  Simple', text: 'IRA: Simple' },
        { value: 'IRA:  Traditional', text: 'IRA: Traditional' },
        { value: 'Margin Account', text: 'Margin Account' },
        {
          value: 'Medical Savings Account (MSA)',
          text: 'Medical Savings Account (MSA)',
        },
        { value: 'Options Account', text: 'Options Account' },
        { value: 'Type 1 Cash Account', text: 'Type 1 Cash Account' },
        { value: 'UGMA Custodial Account', text: 'UGMA Custodial Account' },
        { value: 'UTMA Custodial Account', text: 'UTMA Custodial Account' },
        { value: 'Other', text: 'Other' },
      ],
    },
    {
      component: 'input',
      label: 'Brokerage company',
      name: 'brokerage',
      type: 'text',
      required: true,
      colObject: true,
    },
    {
      component: 'input',
      label: 'Website',
      name: 'website',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Financial advisor',
      name: 'financialAdvisor',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Account name',
      name: 'account',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Account number',
      name: 'accountNumber',
      type: 'text',
    },
    {
      component: 'input',
      label: 'Name(s) on account',
      name: 'names',
      type: 'text',
      description:
        'Be sure to share the exact name(s) on each of your investment accounts along with access information with your loved ones. Investment accounts are often in the name of a trust.',
    },
    {
      component: 'input',
      label: 'Login username (or its location)',
      name: 'username',
      type: 'text',
    },
    {
      component: 'password',
      label: 'Account password (or its location)',
      name: 'password',
      type: 'password',
    },
    {
      component: 'input',
      label: 'PIN number (or its location)',
      name: 'pin',
      type: 'number',
    },
    {
      component: 'input',
      label: 'Beneficaries',
      name: 'beneficaries',
      type: 'text',
      description:
        'The people you name to receive your investment account assets after you pass away. You should review your beneficiaries periodically to keep this information current, and your loved ones should know who the beneficiaries are.',
    },
    {
      component: 'textarea',
      label: 'Notes',
      name: 'notes',
    },
    {
      component: 'input',
      label: 'Location of files / documents',
      name: 'fileLocation',
      type: 'text',
    },
  ],
};
