export const propertyInsuranceData = {
  formTitle: "Add or edit property insurance",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/propertyInsurance",
  formFields: [
    {
      component: "select",
      label: "Property insurance type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Auto", text: "Auto" },
        { value: "Boat", text: "Boat" },
        { value: "Commercial", text: "Commercial" },
        { value: "Condo / Co-op", text: "Condo / Co-op" },
        { value: "Earthquake", text: "Earthquake" },
        { value: "Fire", text: "Fire" },
        { value: "Flood", text: "Flood" },
        { value: "Homeowners", text: "Homeowners" },
        { value: "Industrial", text: "Industrial" },
        { value: "Landlord", text: "Landlord" },
        { value: "Mobile home", text: "Mobile home" },
        { value: "Plane", text: "Plane" },
        { value: "Property Casualty", text: "Property Casualty" },
        { value: "Renters", text: "Renters" },
        { value: "Umbrella", text: "Umbrella" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Property description",
      name: "description",
      type: "text",
    },
    {
      component: "input",
      label: "Property location",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Name of insured",
      name: "insured",
      type: "text",
      description: "The person that the insurance policy covers.",
    },
    {
      component: "input",
      label: "Name of person who receives copy of bills if I am disabled",
      name: "copyPerson",
      type: "text",
      description:
        "This ensures that any required policy premiums are paid on time in the event of death/disability.",
    },
    {
      component: "input",
      label: "Policy owner",
      name: "policyOwner",
      type: "text",
      description:
        "The insured person or a representative of the insured (such as a spouse or trustee) should the insured become disabled.",
    },
    {
      component: "input",
      label: "Beneficiary contact name",
      name: "beneficiary",
      type: "text",
    },
    {
      component: "input",
      label: "Person(s) who have copies of this document",
      name: "persons",
      type: "text",
    },
    {
      component: "input",
      label: "Insurance company",
      name: "company",
      type: "text",
    },
    {
      component: "input",
      label: "Website",
      name: "website",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Customer service phone number",
      name: "csPhone",
    },
    {
      component: "input",
      label: "Broker name",
      name: "broker",
      type: "text",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Account password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "number",
    },
    {
      component: "input",
      label: "Coverage limits",
      name: "coverageLimits",
      type: "text",
      description:
        "The maximum amount of money an insurance company will pay for a covered claim.",
    },
    {
      component: "input",
      label: "Annual premium",
      name: "annualPremium",
      type: "text",
    },
    {
      component: "input",
      label: "Date of most recent review of this policy",
      name: "recentReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Date of next review",
      name: "nextReviewDate",
      type: "date",
    },
    {
      component: "input",
      label: "Expiration date",
      name: "expirationDate",
      type: "date",
    },
    {
      component: "input",
      label: "Mortgage company",
      name: "mortgageCompany",
      type: "text",
    },
    {
      component: "input",
      label: "Mortgage company address",
      name: "mortgageCompanyAddress",
      type: "text",
    },
    {
      component: "input",
      label: "Loan number",
      name: "loanNumber",
      type: "text",
    },
    {
      component: "input",
      label: "Mortgage website",
      name: "mortgageWebsite",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Mortgage phone",
      name: "mortgagePhone",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
