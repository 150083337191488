export const bankAccountsData = {
  formTitle: "Add or edit bank account",
  formSubtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  pathname: "/bankAccounts",
  formFields: [
    {
      component: "select",
      label: "Account Type",
      name: "type",
      type: "text",
      required: true,
      colObject: true,
      options: [
        { value: "Broker", text: "Broker" },
        { value: "Business checking", text: "Business checking" },
        {
          value: "Certificate of Deposit (CD)",
          text: "Certificate of Deposit (CD)",
        },
        { value: "Checking", text: "Checking" },
        { value: "Investment", text: "Investment" },
        { value: "Money market", text: "Money market" },
        { value: "Retirement", text: "Retirement" },
        { value: "Savings", text: "Savings" },
        { value: "Other", text: "Other" },
      ],
    },
    {
      component: "input",
      label: "Bank name",
      name: "bank",
      type: "text",
      required: true,
      colObject: true,
    },
    {
      component: "input",
      label: "Branch location",
      name: "location",
      type: "text",
    },
    {
      component: "input",
      label: "Country",
      name: "country",
      type: "text",
    },
    {
      component: "input",
      label: "Name(s) on account",
      name: "names",
      type: "text",
    },
    {
      component: "input",
      label: "Where I keep my checkbooks",
      name: "checkbooks",
      type: "text",
    },
    {
      component: "input",
      label: "Bank website",
      name: "website",
      type: "text",
    },
    {
      component: "input",
      label: "Routing number",
      name: "routingNumber",
      type: "number",
    },
    {
      component: "input",
      label: "Account number",
      name: "accountNumber",
      type: "number",
    },
    {
      component: "input",
      label: "Login username (or its location)",
      name: "username",
      type: "text",
    },
    {
      component: "password",
      label: "Password (or its location)",
      name: "password",
      type: "password",
    },
    {
      component: "input",
      label: "PIN number (or its location)",
      name: "pin",
      type: "number",
    },
    {
      component: "input",
      label: "Name(s) of bank contact",
      name: "bankContact",
      type: "text",
    },
    {
      component: "phoneNumber",
      label: "Bank contact phone number",
      name: "bankPhone",
    },
    {
      component: "input",
      label: "Beneficaries",
      name: "beneficaries",
      type: "text",
    },
    {
      component: "textarea",
      label: "Notes",
      name: "notes",
    },
    {
      component: "input",
      label: "Location of files / documents",
      name: "fileLocation",
      type: "text",
    },
  ],
};
