import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Auth } from "aws-amplify";

import Router from "./routes";
import { restGet } from "./store/api";
import { updateCurrentUser, getUserDetails } from "./store/actions";
import GreenBtn from "./components/buttons/greenBtn";

const App = () => {
  const dispatch = useDispatch();

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
        dispatch(updateCurrentUser(user));
      } catch (error) {
        dispatch(updateCurrentUser(null));
      }
      setIsAuthenticating(false);
    }
    fetchData();

    restGet(
      "/users/show",
      (data) => dispatch(getUserDetails(data)),
      (err) => console.error(err)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const channel = new BroadcastChannel("user-activity");

    if (isLoggedIn) {
      let inactivityTimeout = null;
      let isModalVisible = false;

      const signOut = async () => {
        try {
          await Auth.signOut();
          localStorage.setItem("logout", Date.now()); // Broadcast the logout event to other tabs
          setIsLoggedIn(false);
        } catch (error) {
          console.error(error);
        }
      };

      const resetInactivityTimeout = () => {
        inactivityTimeout = setTimeout(() => {
          isModalVisible = true;
          setModalVisible(isModalVisible);
          signOut();
          channel.postMessage("logout");
        }, 900000);
      };

      const handleUserActivity = () => {
        if (isModalVisible) {
          isModalVisible = false;
          setModalVisible(isModalVisible);
        }
        resetInactivityTimeout();
        channel.postMessage("activity");
        localStorage.setItem("lastActivity", Date.now()); // Broadcast the user's activity to other tabs
      };

      resetInactivityTimeout();
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("mousedown", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);

      channel.onmessage = (event) => {
        if (event.data === "activity") {
          resetInactivityTimeout();
        } else if (event.data === "logout") {
          signOut();
        }
      };

      window.addEventListener("storage", (event) => {
        if (event.key === "lastActivity") {
          resetInactivityTimeout();
        } else if (event.key === "logout") {
          signOut();
        }
      });

      return () => {
        clearTimeout(inactivityTimeout);
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("mousedown", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
        channel.close();
      };
    }
  }, [isLoggedIn]);

  const handleModalButton = () => {
    setModalVisible(false);
    window.location.href = "/signin";
  };

  return (
    <>
      {!isAuthenticating && <Router />}

      {modalVisible && (
        <div className="fixed z-50 inset-0 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 space-y-2">
                <p className="text-lg leading-5 font-medium text-gray-900" id="modal-headline">
                  Your session has been logged out due to inactivity.
                </p>

                <p className="text-lg leading-5 font-medium text-gray-900" id="modal-headline">
                  Click below to log back in again.
                </p>
              </div>

              <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <GreenBtn onClick={handleModalButton} type="button">
                  Login
                </GreenBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;

