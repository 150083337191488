import { useEffect, useMemo, useState } from "react";

import ExcelJS from "exceljs";
import moment from "moment";

import PageTitle from "../common/page-title";
import { mainBranches } from "../private-layout/data";
import { restGetSummary } from "../../store/api";

const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const insertSpacesBeforeCapital = (text) => {
    return text?.replace(/([a-z])([A-Z])/g, "$1 $2");
};

// Replaces the abbreviations with the full word
const replaceAbbreviations = (text) => {
    const abbreviationWords = {
        tod: "Transfer on Death",
        pod: "Pay on Death",
        poa: "Powers of Attorney",
    };

    return text?.replace(/\b(tod|pod|poa)\b/gi, function (match) {
        return abbreviationWords[match.toLowerCase()];
    });
};

// Adds an 's' to end of certain words
const pluralizeText = (text) => {
    const wordsToPluralize = [
        "testament",
        "trust",
        "ownership",
        "account",
        "individual",
        "organization",
    ];

    let words = text?.split(" ");

    words = words?.map((word) => {
        const lowerCaseWord = word.toLowerCase();

        if (wordsToPluralize.includes(lowerCaseWord)) {
            return word + "s";
        } else {
            return word;
        }
    });

    return words?.join(" ");
};

// Adds '&' after 'will', and ':' after 'gifting'
const addSymbols = (text) => {
    let words = text?.split(" ");

    words = words?.map((word) => {
        const lowerCaseWord = word.toLowerCase();

        if (lowerCaseWord === "will") {
            return word + " &";
        } else if (lowerCaseWord === "gifting") {
            return word + ":";
        }

        return word;
    });

    return words?.join(" ").replace(/ &/g, " &").replace(/:/g, ": ");
};

const formatTopic = (text) => {
    const textWithSpaces = insertSpacesBeforeCapital(text);

    const textWithoutAbbreviations = replaceAbbreviations(textWithSpaces);

    const pluralText = pluralizeText(textWithoutAbbreviations);

    return addSymbols(pluralText);
};

export function LiabilitySummary({ name, title }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const handleExportExcel = async () => {
        const saveAs = require("file-saver");

        const date = moment().format("L");

        const header = `Liability Summary Report as of ${date}`;
        const columns = [
            "Branch",
            "Topic",
            "Liability",
            "Debtor",
            "Estimated Value",
            "Location of Document",
        ];

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Liability Summary Report");

        // Add header row
        worksheet.mergeCells("A1:F1");
        const headerRow = worksheet.getCell("A1");
        headerRow.value = header;
        headerRow.font = { bold: true, size: 14, color: { argb: "FFFFFFFF" } };
        headerRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF6C8954" },
        };
        headerRow.alignment = { vertical: "middle", horizontal: "center" };

        // Add column headers
        worksheet.addRow([]);
        const columnRow = worksheet.addRow(columns);
        columnRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FF808080" },
            };
            cell.alignment = { vertical: "middle", horizontal: "center" };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });

        // Add data rows
        data.forEach((item) => {
            const row = worksheet.addRow([
                capitalizeFirstLetter(item.branch),
                formatTopic(item.topic),
                item.liability || "N/A",
                item.debtor || "N/A",
                item.estimatedValue || "N/A",
                item.locationOfDocument || "N/A",
            ]);

            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            });
        });

        // Add total row
        const totalRow = worksheet.addRow([
            "",
            "",
            "",
            "Estimated total",
            `${total}`,
            "",
            ""
        ]);
        totalRow.eachCell((cell, colNumber) => {
            if (colNumber >= 4 && colNumber < 6) {
                cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FF6C8954" },
                };
                cell.alignment = { vertical: "middle", horizontal: "right" };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            }
        });

        worksheet.columns = [
            { width: 20 }, // Branch
            { width: 30 }, // Topic
            { width: 20 }, // Liability
            { width: 20 }, // Debtor
            { width: 20 }, // Estimated Value
            { width: 20 }, // Location of Document
        ];

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the ArrayBuffer
        const excelBlob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Trigger file download
        saveAs(excelBlob, `Liability_Summary_Report_${date}.xlsx`);
    };

    const liabilityTopics = useMemo(() => {
        const branch = mainBranches.find(
            (branch) => branch.path === "everyday"
        );
        const subBranch = branch?.subBranches.find(
            (subBranch) => subBranch.path === "finances"
        );
        const topics = subBranch?.topics || [];
        const filteredTopics = topics.filter(topic => topic.name.includes("LIABILITIES"));

        return filteredTopics;
    }, []);

    const formattedTotal = (total) => {
        let totalWithoutCommas = total;
        if (typeof total === "string") {
            totalWithoutCommas = total.replace(/,/g, "");
        }

        if (isNaN(parseFloat(totalWithoutCommas))) {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(0);
        }

        const convertTotal = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(totalWithoutCommas);

        return convertTotal;
    };

    const calculateTotal = (data) => {
        let total = 0;

        data.forEach((item) => {
            if (item.estimatedValue) {
                let estimatedValue = parseFloat(
                    item.estimatedValue.replace(/[\$,]/g, "")
                );

                if (!isNaN(estimatedValue)) {
                    total += estimatedValue;
                }
            }
        });

        setTotal(formattedTotal(total));
    }

    const modelLiabilityTopics = liabilityTopics
        .map((item) => item.path)
        .concat();

    const getDataAssetSummary = () => {
        restGetSummary(
            "/liability-summary",
            modelLiabilityTopics,
            (data) => {
                setData(data);
                calculateTotal(data);
            },
            (error) => console.error(error)
        );
    };

    useEffect(() => {
        getDataAssetSummary();
    }, []);

    const topicReplacements = {
        AccountPayable: "accountsPayable",
        Loan: "loans",
        LiabilityLease: "liabilitiesLeases",
        OutstandingTaxDue: "outstandingTaxesDue",
    };

    console.log("data: ", data);

    return (
        <>
            <PageTitle title="My Banyan" />

            <div className="mb-8">
                <div className="mx-auto flex max-w-7xl flex-col pt-4 md:pt-6 lg:pt-8">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-8 py-4 md:px-8">
                            <div className="flex flex-col md:flex-row items-center justify-between">
                                <div className="md:w-1/4 text-2xl uppercase text-primary-green font-bold">
                                    {name}
                                </div>

                                <div className="md:w-3/4 pt-2 md:pt-0 text-sm">
                                    <p>
                                        This report summarizes the information
                                        in your LIABILITY. To add or
                                        update any data, click on a cell in the
                                        “TOPIC” or the “ITEM” column. You will
                                        be taken to that record where you can
                                        make any changes you like. To print a
                                        copy of your report, click on “DOWNLOAD”
                                        and you will receive your summary report
                                        as am Excel spreadsheet.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 py-4 md:px-8">
                            <hr className="border-t-2 border-lime-600" />
                        </div>

                        <div className="flex px-8 py-4 md:px-8 justify-center md:justify-end">
                            <button
                                className="rounded-full border border-transparent bg-secondary-green py-2 px-12 text-sm font-medium text-black hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300"
                                onClick={handleExportExcel}
                            >
                                Download
                            </button>
                        </div>

                        <div className="px-8 py-4 md:px-8 overflow-y-auto">
                            <table className="w-full border-collapse border border-gray-300">
                                <thead className="px-8 py-4">
                                    <tr className="uppercase font-thin text-white bg-[#7A935A] text-base">
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Branch
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Topic
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Liability
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Debtor
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Estimated Value
                                        </th>
                                        <th className="w-1/5 border border-slate-600 px-4 py-2">
                                            Location of Document
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="text-thin font-medium"
                                        >
                                            {/* BRANCH */}
                                            <td className="border border-slate-700 text-center px-4 py-2">
                                                {capitalizeFirstLetter(
                                                    item.branch
                                                )}
                                            </td>

                                            {/* TOPIC */}
                                            <td className="border border-slate-700 px-4 py-2">
                                                <a
                                                    href={`/everyday/finances/${
                                                        topicReplacements[
                                                            item.topic
                                                        ] || item.topic
                                                    }`}
                                                    target="_blank"
                                                    className="text-blue-600 underline"
                                                    rel="noreferrer"
                                                >
                                                    {formatTopic(item.topic)}
                                                </a>
                                            </td>

                                            {/* LIABILITY */}
                                            <td className="italic border border-slate-700 px-4 py-2">
                                                {item.liability ? (
                                                    <a
                                                        href={`/everyday/finances/${
                                                            topicReplacements[
                                                                item.topic
                                                            ] || item.topic
                                                        }/edit?id=${item.id}`}
                                                        target="_blank"
                                                        className={`text-blue-600 underline`}
                                                        rel="noreferrer"
                                                    >
                                                        <p>{item.liability}</p>
                                                    </a>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>

                                            {/* DEBTOR */}
                                            <td
                                                className={`italic border border-slate-700 px-4 py-2`}
                                            >
                                                {item.debtor ? (
                                                    <p>{item.debtor}</p>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>

                                            {/* ESTIMATED VALUE */}
                                            <td
                                            className={`text-black italic border border-slate-700 px-4 py-2 text-right`}
                                            >
                                            {item.estimatedValue ? (
                                                <p>
                                                {item.estimatedValue.includes('$') 
                                                    ? `$${item.estimatedValue.replace(/\$/g, '').replace(/,/g, '')}`  // Ensures only one $ and removes commas
                                                    : `$${item.estimatedValue}`}
                                                </p>
                                            ) : (
                                                <p>N/A</p>
                                            )}
                                            </td>

                                            {/* LOCATION OF DOCUMENT */}
                                            <td
                                                className={`italic border border-slate-700 px-4 py-2`}
                                            >
                                                {item.locationOfDocument ? (
                                                    <p>
                                                        {item.locationOfDocument}
                                                    </p>
                                                ) : (
                                                    <p>N/A</p>
                                                )}
                                            </td>
                                        </tr>
                                    ))}

                                    <tr className="text-white">
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className=""></td>
                                        <td className="bg-[#7A935A] border border-slate-700 font-bold px-4 py-2">
                                            Estimated total
                                        </td>
                                        <td className="bg-[#7A935A] border border-slate-700 text-white font-bold px-4 py-2 text-right">
                                            {total}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

